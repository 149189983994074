import {
  generateSimpleAction,
  generateRequestActions,
  createRequestTypes,
} from 'utils/actionHelpers'

export const PRO_CREATE = createRequestTypes('PRO_CREATE')
export const PRO_AUTH_LOGIN = createRequestTypes('PRO_AUTH_LOGIN')
export const PRO_AUTH_LOGOUT = 'PRO_AUTH_LOGOUT'
export const PRO_SET_PRO_FORM_REGISTRATION_STEP =
  'PRO_SET_PRO_FORM_REGISTRATION_STEP'
export const PRO_AUTH_SET_LOGGED_IN = 'PRO_AUTH_SET_LOGGED_IN'
export const PRO_AUTH_SET_FIRM_ID = 'PRO_AUTH_SET_FIRM_ID'
export const PRO_AUTH_SET_NICKNAME = 'PRO_AUTH_SET_NICKNAME'
export const PRO_AUTH_SET_EMAIL = 'PRO_AUTH_SET_EMAIL'
export const PRO_AUTH_SET_ROLES = 'PRO_AUTH_SET_ROLES'
export const PRO_SET_SPN = 'PRO_SET_SPN'
export const PRO_SET_TABS = 'PRO_SET_TABS'
export const REGISTRATION_NUMBER_DATA = createRequestTypes(
  'REGISTRATION_NUMBER_DATA',
)
export const SET_REGISTRATION_NUMBER_DATA = 'SET_REGISTRATION_NUMBER_DATA'
export const PRO_FORGOT_PASSWORD = createRequestTypes('PRO_FORGOT_PASSWORD')
export const PRO_UPDATE_PASSWORD = createRequestTypes('PRO_UPDATE_PASSWORD')
export const PRO_REGISTRATION = createRequestTypes('PRO_REGISTRATION')
export const GET_PREFILLED_REGISTRATION = createRequestTypes(
  'GET_PREFILLED_REGISTRATION',
)
export const SET_CONFIGURATION_PACKAGE_ADDRESS_FORM_ERROR =
  'SET_CONFIGURATION_PACKAGE_ADDRESS_FORM_ERROR'

export const proCreate = generateRequestActions(PRO_CREATE)
export const proAuthLogin = generateRequestActions(PRO_AUTH_LOGIN)
export const proAuthLogout = generateSimpleAction(PRO_AUTH_LOGOUT)
export const setProLoggedIn = generateSimpleAction(PRO_AUTH_SET_LOGGED_IN)
export const setProFirmId = generateSimpleAction(PRO_AUTH_SET_FIRM_ID)
export const setNickname = generateSimpleAction(PRO_AUTH_SET_NICKNAME)
export const setEmail = generateSimpleAction(PRO_AUTH_SET_EMAIL)
export const setProRoles = generateSimpleAction(PRO_AUTH_SET_ROLES)
export const proSetSpn = generateSimpleAction(PRO_SET_SPN)
export const proSetTabs = generateSimpleAction(PRO_SET_TABS)
export const proForgotPassword = generateRequestActions(PRO_FORGOT_PASSWORD)
export const registrationNumberData = generateRequestActions(
  REGISTRATION_NUMBER_DATA,
)
export const setRegistrationNumberData = generateSimpleAction(
  SET_REGISTRATION_NUMBER_DATA,
)
export const proResetPassword = generateRequestActions(PRO_UPDATE_PASSWORD)
export const proRegistration = generateRequestActions(PRO_REGISTRATION)
export const setProFormRegistrationStep = generateSimpleAction(
  PRO_SET_PRO_FORM_REGISTRATION_STEP,
)
export const getPrefilledRegistration = generateRequestActions(
  GET_PREFILLED_REGISTRATION,
)
export const setConfigurationPackageAddressFormError = generateSimpleAction(
  SET_CONFIGURATION_PACKAGE_ADDRESS_FORM_ERROR,
)
